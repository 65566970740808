// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-page-collection-js": () => import("./../src/templates/page-collection.js" /* webpackChunkName: "component---src-templates-page-collection-js" */),
  "component---src-templates-page-cubetto-js": () => import("./../src/templates/page-cubetto.js" /* webpackChunkName: "component---src-templates-page-cubetto-js" */),
  "component---src-templates-page-ebook-js": () => import("./../src/templates/page-ebook.js" /* webpackChunkName: "component---src-templates-page-ebook-js" */),
  "component---src-templates-page-edu-casestudies-js": () => import("./../src/templates/page-edu-casestudies.js" /* webpackChunkName: "component---src-templates-page-edu-casestudies-js" */),
  "component---src-templates-page-edu-resources-js": () => import("./../src/templates/page-edu-resources.js" /* webpackChunkName: "component---src-templates-page-edu-resources-js" */),
  "component---src-templates-page-education-js": () => import("./../src/templates/page-education.js" /* webpackChunkName: "component---src-templates-page-education-js" */),
  "component---src-templates-page-faq-js": () => import("./../src/templates/page-faq.js" /* webpackChunkName: "component---src-templates-page-faq-js" */),
  "component---src-templates-page-home-js": () => import("./../src/templates/page-home.js" /* webpackChunkName: "component---src-templates-page-home-js" */),
  "component---src-templates-page-materials-js": () => import("./../src/templates/page-materials.js" /* webpackChunkName: "component---src-templates-page-materials-js" */),
  "component---src-templates-page-partners-js": () => import("./../src/templates/page-partners.js" /* webpackChunkName: "component---src-templates-page-partners-js" */),
  "component---src-templates-page-playroom-js": () => import("./../src/templates/page-playroom.js" /* webpackChunkName: "component---src-templates-page-playroom-js" */),
  "component---src-templates-page-product-js": () => import("./../src/templates/page-product.js" /* webpackChunkName: "component---src-templates-page-product-js" */),
  "component---src-templates-page-shop-js": () => import("./../src/templates/page-shop.js" /* webpackChunkName: "component---src-templates-page-shop-js" */),
  "component---src-templates-page-sitemap-js": () => import("./../src/templates/page-sitemap.js" /* webpackChunkName: "component---src-templates-page-sitemap-js" */),
  "component---src-templates-page-stockists-js": () => import("./../src/templates/page-stockists.js" /* webpackChunkName: "component---src-templates-page-stockists-js" */),
  "component---src-templates-page-story-js": () => import("./../src/templates/page-story.js" /* webpackChunkName: "component---src-templates-page-story-js" */),
  "component---src-templates-page-terms-js": () => import("./../src/templates/page-terms.js" /* webpackChunkName: "component---src-templates-page-terms-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

