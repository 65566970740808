import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import app from '../reducers/app'
import ui from '../reducers/ui'

export default function configureStore () {
  const persistConfig = {
    key: 'root',
    storage,
    timeout: null,
    whitelist: ['app']
  }

  const rootReducer = combineReducers({
    app,
    ui
  })

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = createStore(
    persistedReducer,
    compose(
      applyMiddleware(thunk)
    )
  )

  const persistor = persistStore(store)

  return { store, persistor }
}
