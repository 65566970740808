import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

export function initErrorBoundary () {
  Bugsnag.start({
    apiKey: '9333de9c002795584659753f5ecaf531',
    plugins: [new BugsnagPluginReact(React)]
  })

  return Bugsnag.getPlugin('react')
}

export function trackError (err, opts) {
  try {
    if (typeof window !== 'undefined') {
      if (typeof err === 'string') {
        err = new Error(err)
      }
      console.log(err.message, opts)
      Bugsnag.notify(err, opts)
    }
  } catch (e) {}
}