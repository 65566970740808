export const COOKIE_NOTICE_ACKNOWLEDGED = 'COOKIE_NOTICE_ACKNOWLEDGED'
export const GEOLOCATE = 'GEOLOCATE'

export const acknowledgeCookieNotice = () => ({ type: COOKIE_NOTICE_ACKNOWLEDGED })

const HOUR = 1000 * 60 * 60
const DAY = HOUR * 24

async function getGeolocation(siteURL) {
  try {
    const t = `${Date.now()}-${Math.floor(Math.random() * 10000)}`
    const response = await fetch(`${siteURL}/geolocate.php?t=${t}`)
    if (response.status < 400) {
      const result = await response.json()
      return result.countryCode
    }
    return null
  } catch (error) {
    console.error(error)
    return null
  }
}

function getBrowserGeolocation() {
  try {
    const opts = Intl.DateTimeFormat().resolvedOptions()
    const countryCode = opts.locale.split('-').pop()
    if (
      countryCode !== 'AU' &&
      opts.timeZone &&
      opts.timeZone.split('/').shift() === 'Australia'
    ) {
      return 'AU'
    }
    return countryCode
  } catch (error) {
    console.error(error)
    return null
  }
}

export const geolocate = (siteURL) => async (dispatch, getState) => {
  try {
    const { geolocation, geoUpdatedAt } = getState().app
    if (!geolocation || Date.now() - geoUpdatedAt > DAY * 7) {
      let result = await getGeolocation(siteURL)
      console.log('geo detect result = ', result)
      if (!result) {
        result = getBrowserGeolocation()
      }
      if (result) {
        dispatch({ type: GEOLOCATE, result, updatedAt: Date.now() })
      }
    }
  } catch (e) {
    console.error(e)
  }
}

export const mockGeolocate = (countryCode) => async (dispatch) => {
  if (countryCode === '0') countryCode = null
  dispatch({ type: GEOLOCATE, result: countryCode, updatedAt: Date.now() })
}
