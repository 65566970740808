import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from './configure-store'
import { initErrorBoundary } from '../utils/track'

export default function wrapWithProvider ({ element }) {
  const { store, persistor } = configureStore()

  if (typeof document !== 'undefined') {
    const ErrorBoundary = initErrorBoundary()

    return (
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            {element}
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    )
  }

  return (
    <Provider store={store}>
      {element}
    </Provider>
  )
}
