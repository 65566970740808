import {
  COOKIE_NOTICE_ACKNOWLEDGED,
  GEOLOCATE
} from '../actions/app'

const defaultState = {
  cookieNoticeAcknowledged: false,
  geolocation: null,
  geoUpdatedAt: 0
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case COOKIE_NOTICE_ACKNOWLEDGED:
      return {
        ...state,
        cookieNoticeAcknowledged: true
      }
    case GEOLOCATE:
      return {
        ...state,
        geolocation: action.result,
        geoUpdatedAt: action.updatedAt
      }
    default:
      return state
  }
}
