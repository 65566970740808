/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/css/modernizr.js'
import './src/css/styles.min.css'

import wrapWithProvider from './src/store/wrap-with-provider'
export const wrapRootElement = wrapWithProvider

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  if (location.hash) {
    return false
  }

  return true
}
  