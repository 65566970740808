import {
  TOGGLE_MOBILE_MENU_OPEN,
  DISTRO_OPEN,
  BANNER_DISMISSED
} from '../actions/ui'

const defaultState = {
  mobileMenuOpen: false,
  bannerDismissed: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case TOGGLE_MOBILE_MENU_OPEN:
      if (typeof action.force === 'boolean') {
        return {
          ...state,
          mobileMenuOpen: action.force
        }
      }
      return {
        ...state,
        mobileMenuOpen: !state.mobileMenuOpen
      }
    case DISTRO_OPEN:
      return {
        ...state,
        distroOpen: action.value
      }
    case BANNER_DISMISSED:
      return {
        ...state,
        bannerDismissed: true
      }
    default:
      return state
  }
}
